











































import Vue from 'vue'
import { namespace } from 'vuex-class'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { required } from 'vuelidate/lib/validators'
import { DateTime } from 'luxon'

import { getIconByKey } from '@/utils/icons'
import { ERROR_TOAST } from '@/utils/toasts'
import Header from '@/components/generic/Header.vue'
import { CustomerAppointmentResponse, CustomerAppointmentStatusResponse, DilapidatedReason } from '@/api/types'
import AppointmentDetail from '@/components/AppointmentDetail.vue'
import Loading from '@/components/generic/Loading.vue'
import CallToAction from '@/components/generic/buttons/CallToAction.vue'
import TextArea from '@/components/generic/TextArea.vue'
import extractErrorMessage from '@/utils/errorMessages'

const appointmentStatusNamespace = namespace('appointmentStatus')
const proposalsStatusNamespace = namespace('proposals')
const priceNamespace = namespace('price')

@Component({
  name: 'AppointmentStatus',
  components: {
    TextArea,
    CallToAction,
    Loading,
    AppointmentDetail,
    Header
  },
  validations: {}
})
export default class AppointmentStatus extends Vue {
  @appointmentStatusNamespace.State(state => state.customerAppointmentStatusFailure) customerAppointmentStatusFailure!: boolean | null
  @appointmentStatusNamespace.State(state => state.customerAppointmentFailure) customerAppointmentFailure!: boolean | null
  @appointmentStatusNamespace.State(state => state.customerAppointmentStatus) customerAppointmentStatus!: CustomerAppointmentStatusResponse | null
  @appointmentStatusNamespace.State(state => state.customerAppointment) customerAppointment!: CustomerAppointmentResponse | null
  @appointmentStatusNamespace.Getter('statusText') statusText!: string | null

  @appointmentStatusNamespace.State(state => state.dilapidatedReasons) dilapidatedReasons!: DilapidatedReason[]
  @proposalsStatusNamespace.State(state => state.selectedProposal) selectedProposal!: [] | null

  @priceNamespace.State(state => state.priceText) priceText!: string | null
  @priceNamespace.State(state => state.priceTextFailure) priceTextFailure!: boolean | null

  dilapidatedReason: string | null = null
  dilapidatedRemark: string | null = null

  private readonly dilapidatedReasonOther = '14'

  mounted() {
    const urlParams = this.$route.params

    if (!urlParams.postalCode || !urlParams.houseNumber) {
      this.$router.replace({ name: 'appointmentCheckStatus' })
    }

    this.$store.commit('address/updatePostcode', urlParams.postalCode)
    this.$store.commit('address/updateHousenumber', urlParams.houseNumber)
    this.$store.commit('address/updateAddition', urlParams.addition)

    this.$store.dispatch('appointmentStatus/getCustomerAppointmentStatus')
  }

  @Watch('customerAppointmentFailure')
  onCustomerAppointmentFailureChange(value: boolean | null) {
    if (value === true) {
      this.$bvToast.toast('De afspraak kon niet worden opgehaald.', ERROR_TOAST)
    } else if (value === false) {
      this.$store.dispatch('address/restoreFromAppointmentStatus')
      this.$store.dispatch('appointment/restoreFromAppointmentStatus')
      this.$store.dispatch('problemAreas/restoreFromAppointmentStatus')
      this.$store.dispatch('contactDetails/restoreFromAppointmentStatus')
    }
  }

  @Watch('customerAppointmentStatusFailure')
  onCustomerAppointmentStatusFailureChange(value: boolean | null) {
    const currentState = this.customerAppointmentStatus?.orderState

    if (value === false) {
      if (currentState === 'planned') {
        this.$store.dispatch('appointmentStatus/getCustomerAppointment')
      }
      if (currentState !== 'dilapidated') {
        this.$store.dispatch('price/fetchAppointmentPrice')
      }
    }
  }

  @Watch('priceTextFailure')
  onPriceTextFailureChange(value: boolean | null) {
    if (value === true) {
      this.$bvToast.toast('Prijsinformatie kon niet worden opgehaald, probeer het later opnieuw', ERROR_TOAST)
    }
  }

  @Watch('dilapidatedReason')
  onReasonChange(newValue: string, previousValue: string) {
    if (previousValue === this.dilapidatedReasonOther) {
      // touch reason to revalidate (since the required is possibly dropped)
      this.$v.dilapidatedRemark.$touch()
    }
  }

  validations() {
    if (this.dilapidatedReason === this.dilapidatedReasonOther) {
      return {
        dilapidatedRemark: { required },
        dilapidatedReason: { required }
      }
    }
    return {
      dilapidatedRemark: {},
      dilapidatedReason: { required }
    }
  }

  getIconByKey(key: string) {
    return getIconByKey(key)
  }

  openCancelModal() {
    this.$store.dispatch('appointmentStatus/fetchDilapidatedReasons')
    this.$bvModal.show('confirmModal')
  }

  cancelAppointment(event: MouseEvent) {
    this.$v.$touch()
    if (this.$v.$invalid) {
      event.preventDefault()
    } else {
      this.$store.dispatch('appointment/cancelCustomerAppointment', {
        reason: this.dilapidatedReason,
        remark: this.dilapidatedRemark
      }).then(() => {
        this.$router.replace('/geannuleerd')
      }).catch(() => {
        this.$bvToast.toast('Uw afspraak kon niet worden geannuleerd', ERROR_TOAST)
      })
    }
  }

  get cancelAllowed() {
    if ((!(this.customerAppointmentStatus))) {
      return false
    }
    const appointmentTimeFrom = DateTime.fromISO(this.customerAppointmentStatus?.from)
    const diff = appointmentTimeFrom.diffNow('hours')
    return diff.hours > 24
  }

  get changeAllowed() {
    if (!(this.customerAppointmentStatus)) {
      return false
    }

    const appointmentTimeFrom = DateTime.fromISO(this.customerAppointmentStatus?.from)
    const diff = appointmentTimeFrom.diffNow('hours')
    const currentState = this.customerAppointmentStatus?.orderState

    if (currentState === 'planned' && diff.hours > 24) {
      const changes = this.customerAppointmentStatus?.changecount ?? 0
      return changes < 2
    }
    return false
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorMessage(field: any) {
    return extractErrorMessage(field)
  }
}
