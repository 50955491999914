












































import Vue from 'vue'
import { required } from 'vuelidate/lib/validators'
import Component from 'vue-class-component'

import { isPostcode } from '@/utils/validators'
import Header from '@/components/generic/Header.vue'
import TextInput from '@/components/generic/TextInput.vue'
import CallToAction from '@/components/generic/buttons/CallToAction.vue'

@Component({
  name: 'AppointmentCheckStatus',
  components: {
    Header,
    TextInput,
    CallToAction
  },
  validations: {
    postCode: {
      required,
      isPostcode
    },
    houseNumber: { required }
  }
})
export default class AppointmentStatus extends Vue {
  postCode = ''
  houseNumber = ''
  addition = ''

  checkAppointmentStatus() {
    this.$v.$touch()
    if (this.valid) {
      this.$router.push(this.appointmentStatusUrl)
    }
  }

  get appointmentStatusUrl() {
    return '/afspraakstatus/' + this.postCode + '/' + this.houseNumber + '/' + this.addition
  }

  get valid() {
    return (!this.$v.$invalid)
  }
}
